
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import { IStory, IStorySlide } from "@/struct/IStory/IStory";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Options({
  components: { ButtonComponent },
})
export default class StoryViewer extends Vue {
  @Prop({ required: true }) story!: IStory;
  @Prop({ default: false }) isActive!: boolean;
  @Prop({ default: false }) isBackground!: boolean;
  @Prop({ default: false }) background!: string;

  activeSlideIndex = 0;
  slideInterval: number | null = null;
  imageCache: Map<string, string> = new Map(); // Cache for images

  private currentSlide: null | IStorySlide = null;
  private isLoading = false;

  @Watch("activeSlideIndex")
  onActiveSlideIndexChanged() {
    this.currentSlide = this.story.slides[this.activeSlideIndex];
  }

  @Watch("isActive")
  onIsActiveChanged(newVal: boolean) {
    if (newVal) {
      this.preloadImages();
      this.currentSlide = this.story.slides[this.activeSlideIndex];
      document.body.classList.add("overflow-hidden");
      document.body.classList.add("relative");
      this.startSlideShow();
    } else {
      this.stopSlideShow();
      document.body.classList.remove("overflow-hidden");
      document.body.classList.remove("relative");
      this.currentSlide = null;
      this.activeSlideIndex = 0;
      this.isLoading = false;
    }
  }

  handleButtonAction() {
    if (this.currentSlide?.buttonAction) {
      this.$emit("storyActionClick", this.currentSlide.buttonAction);
    }
  }

  preloadImages() {
    const loadPromises = this.story.slides.map((slide) => {
      if (this.isBackground) return;

      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          this.imageCache.set(slide.image as string, img.src);
          resolve(img);
        };
        img.onerror = reject;
        img.src = slide.image as string;
      });
    });

    // Wait for all images to load
    Promise.all(loadPromises)
      .then(() => {
        this.isLoading = true;
      })
      .catch((error) => {
        console.error("Failed to preload images", error);
        this.isLoading = false; // You may want to handle this differently
        this.$emit("end");
      });
  }

  startSlideShow() {
    this.stopSlideShow(); // ensure previous interval is cleared
    this.slideInterval = setInterval(() => this.nextSlide(false), 10000); // change slide every 10s
  }

  stopSlideShow() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
      this.slideInterval = null;
    }
  }

  nextSlide(handleClick: boolean) {
    this.stopSlideShow(); // stop automatic slide show
    if (this.activeSlideIndex < this.story.slides.length - 1) {
      this.activeSlideIndex++;
      this.startSlideShow(); // restart automatic slide show only if it's not the last slide
    } else {
      if (handleClick) {
        this.$emit("end");
      }
    }
  }

  previousSlide() {
    this.stopSlideShow(); // stop automatic slide show
    if (this.activeSlideIndex > 0) {
      this.activeSlideIndex--;
    } else {
      this.$emit("start");
    }
    this.startSlideShow(); // restart automatic slide show
  }
}
