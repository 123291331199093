
import { Prop } from "vue-property-decorator";
import { Vue } from "vue-class-component";

export default class BalanceCard extends Vue {
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) subtitle!: string;
  @Prop({ default: "" }) limit!: number;
  @Prop() public balance!: string;
  @Prop() public amount!: number;
  @Prop() public max_amount!: number;
  @Prop() public min_amount!: number;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isActiveCard!: boolean;

  private filterColor = "";
  private colorBody = "";
  private colorIcon = "";
  private isFocus = false;

  private validateColorBodyAndIcon(): void {
    if (!this.disabled) {
      this.colorBody = "#E5FFD9";
      this.colorIcon = "#43A047";
      return;
    }

    const balance = Number(this.balance);
    if (balance === 0) {
      this.colorBody = "#FFF7D2";
      this.colorIcon = "#FFD92F";
      return;
    }

    if (balance <= 500) {
      this.colorBody = "#FFF1B4";
      this.colorIcon = "#FFDE4C";
      return;
    }

    if (balance <= 1000) {
      this.colorBody = "#FFEC96";
      this.colorIcon = "#FFDE4C";
      return;
    }

    if (balance <= 1500) {
      this.colorBody = "#FFE46A";
      this.colorIcon = "#FFF1B4";
      return;
    }

    if (balance <= 3000) {
      this.colorBody = "#FFDE4C";
      this.colorIcon = "#FFF1B4";
      return;
    }

    if (balance > 3000) {
      this.colorBody = "#FFD92F";
      this.colorIcon = "#FFF1B4";
      return;
    }
  }

  created() {
    this.validateColorBodyAndIcon();
  }
}
