
import StoryItem from "@/components/Stories/StoryItem.vue";
import StoryViewer from "@/components/Stories/StoryViever.vue";
import { IStory } from "@/struct/IStory/IStory";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    StoryItem,
    StoryViewer,
  },
})
export default class StoryList extends Vue {
  private stories: IStory[] = [
    {
      title: "Вывод СБП",
      previewImage: require("@/assets/img/story/sbp/preview.svg"),
      slides: [
        {
          image: require("@/assets/img/story/sbp/slide1.png"),
          title: "Самые низкие комиссии",
          description:
            "Наша новая функция вывода через систему быстрых платежей обеспечивает наименьшие возможные комиссии. \n\n Экономьте больше с каждым переводом!",
        },
        {
          image: require("@/assets/img/story/sbp/slide2.png"),
          title: "Ускоренные переводы",
          description:
            "Благодаря новой системе быстрых платежей, ваши переводы осуществляются мгновенно. \n\n Больше не нужно ждать получения своих средств!",
        },
        {
          image: require("@/assets/img/story/sbp/slide3.png"),
          title: "Простота в  \n добавлении",
          description:
            "Добавление и использование новой системы быстрых платежей в нашем приложении легко и удобно.",
          buttonText: "Подключить СБП",
          buttonAction: "redirectToSbp",
          buttonColor: "primary",
        },
      ],
    },
    {
      title: "Коды в Telegram",
      previewImage: require("@/assets/img/story/t-bot/preview.png"),
      slides: [
        {
          image: require("@/assets/img/story/t-bot/slide1.png"),
          title: "Быстрый вход в через Telegram.",
          description:
            "Получайте код через Telegram, это быстрее чем по звонку.",
          buttonText: "Подключить",
          buttonAction: "redirectToTBot",
          buttonColor: "primary",
        },
        {
          image: require("@/assets/img/story/t-bot/slide2.png"),
          title: "Надежно.",
          description: "Ваши данные защищены безопасным соединением.",
          buttonText: "Подключить",
          buttonAction: "redirectToTBot",
          buttonColor: "primary",
        },
        {
          image: require("@/assets/img/story/t-bot/slide3.png"),
          title: "Просто подключить.",
          description:
            "Чтобы получать коды через Telegram - нажмите кнопку ниже.",
          buttonText: "Подключить",
          buttonAction: "redirectToTBot",
          buttonColor: "primary",
        },
      ],
    },
  ];

  private activeStoryIndex: number | null = null;

  private closeStory() {
    this.activeStoryIndex = null;
  }

  private handeStoryAction(action: string) {
    if (this[action]) {
      // Вызываем функцию из слайда
      this[action]();
    }
  }

  private redirectToSbp() {
    this.closeStory();
    this.$router.push({ path: "/new-card", query: { open: "sbp" } });
  }
  private redirectToTBot() {
    this.closeStory();
    window.open(process.env.VUE_APP_TELEGRAM_URL, "_blank");
  }
}
