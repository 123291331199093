
import { Prop } from "vue-property-decorator";

import { IStory } from "@/struct/IStory/IStory";
import { Vue } from "vue-class-component";

export default class StoryItem extends Vue {
  @Prop({ required: true }) story!: IStory;
  @Prop({ required: false }) title!: string;

  emitClick() {
    this.$emit("click");
  }
}
