
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import { App } from "@capacitor/app";
import { ICard } from "@/struct/IHomePage/ICabinetBalance";

@Options({
  components: { ButtonComponent },
})
export default class StatusModal extends Vue {
  @Prop() public state!: boolean;
  @Prop() public description!: string;
  @Prop() public htmlDescription!: string;
  @Prop() public type!: "error" | "success" | "warning";
  @Prop() public buttonText!: string;
  @Prop() public isPaymentsOff!: boolean;
  @Prop() public cards!: ICard[] | undefined;
  @Prop() public cardIsActivate!: boolean | null;
  @Prop() public isActiveCard!: string;

  private appVersion: string | null = null;

  @Watch("state")
  watchModalState(new_value: boolean) {
    if (new_value) {
      return document.body.classList.add("overflow-hidden");
    }
    if (!new_value) {
      return document.body.classList.remove("overflow-hidden");
    }
  }

  private validateColor(type: "icon" | "button") {
    if (type == "button") {
      if (this.type === "error") return "red";
      if (this.type === "warning") return "primary";
      if (this.type === "success") return "green";
    }

    if (type === "icon") {
      if (this.type === "error") return "#FF5252";
      if (this.type === "warning") return "#FFD92F";
      if (this.type === "success") return "#43A047";
    }
  }

  private onClickOutside() {
    const dialog = document.getElementById("dialog") as HTMLDivElement;
    dialog.classList.add("bounce-modal-animation");
    setTimeout(() => {
      dialog.classList.remove("bounce-modal-animation");
    }, 100);
  }

  unmounted() {
    document.body.classList.remove("overflow-hidden");
  }

  created() {
    if (this.$PLATFORM != "web") {
      App.getInfo().then((info) => {
        this.appVersion = `v${info.version} ${info.build}`;
      });
    }
  }
}
